import React, { useState, useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import Widget from './Widget';
import sumupServiceFactory from '../services/sumupService';
import billingServiceFactory from '../services/billingService';
import epayServiceFactory from '../services/epayService';
import WidgetConfigContext from './WidgetConfigContext';
import crossWindow from '../utils/crossWindow';
import i18n from '../i18n';
import { businessAccountValidationSchema, validateParams } from '../utils/validation';
import { notifyParent } from '../utils/notifyParent';
import { MESSAGE_TYPES } from '../../shared/constants';
import queryParameters from '../utils/queryParameters';
import { parseJwt } from '../utils/parseToken';
import { setUser } from '../utils/monitoring';

const WidgetContainer = () => {
	const [ legalInfo, setLegalInfo ] = useState();
	const [ widgetOffset, setWidgetOffset ] = useState({ offset: 0, innerHeight: 0 });
	const [ businessAccount, setBusinessAccount ] = useState({ enabled: false, firstName: '', lastName: '', iban: '' });
	const [ customerReference, setCustomerReference ] = useState();

	useEffect(() => {
		crossWindow.addMessageListener(MESSAGE_TYPES.LEGAL_INFO, (data) => {
			setLegalInfo(data);
		});
		crossWindow.addMessageListener(MESSAGE_TYPES.ALIGN_OFFSET, (data) => {
			setWidgetOffset(data);
		});
		crossWindow.addMessageListener(MESSAGE_TYPES.BUSINESS_ACCOUNT, (data) => {
			const { success, data: validatedData, error } = businessAccountValidationSchema.safeParse(data);
			if (success) {
				const { businessAccountDirectDebitsEnabled, businessAccountCompanyName, businessAccountIban } = validatedData;
				setBusinessAccount({
					enabled: businessAccountDirectDebitsEnabled,
					companyName: businessAccountCompanyName,
					iban: businessAccountIban
				});
			} else {
				console.error(error, 'Error parsing business account');
			}
		});
	}, []);

	const {
		type,
		token, planId, subscriptionId,
		email, company,
		country, state, city, zip, addressLine1, addressLine2,
		phone, vatNumber, firstName, lastName,
		regionId, regionName,
		_debug,
		showBillingDetails,
		showCoupon, couponCode,
		showVatNumber,
		showDirectDebit,
		showBusinessAccount,
		showPayout,
		legalEntity,
		redirectUrl,
		invoiceInfo,
		showEditMode,
	} = queryParameters;

	useEffect(() => {
		const { customerReference = '', accountId = '' } = parseJwt(token) || {};
		if (customerReference && accountId) {
			setUser({ id: customerReference, accountId });
			setCustomerReference(customerReference);
		}
	}, [token]);

	_debug && i18n.debug.enable();

	const getConfig = useAsync(setupServices, [token]);

	const { valid, error } = validateParams(queryParameters);

	if (!valid && error) {
		Object.assign(getConfig, { error, status: 'error' });
		Object.freeze((getConfig));
	}

	const billingDetails = {
		email, company, country, state, city, zip,
		addressLine1, addressLine2,
		phone, vatNumber, firstName, lastName, invoiceInfo,
		regionId, regionName,
	};

	const availablePaymentMethods = {
		creditCard: true,
		directDebit: showDirectDebit,
		businessAccount: showBusinessAccount && businessAccount.iban && businessAccount.enabled,
		suPayout: showPayout,
	};

	return (
		<WidgetConfigContext.Provider
			value={{ getConfig }}
		>
			<Widget
				type={type}
				showBillingDetails={showBillingDetails}
				showVatNumber={showVatNumber}
				showCoupon={showCoupon}
				billingDetails={billingDetails}
				planId={planId}
				subscriptionId={subscriptionId}
				availablePaymentMethods={availablePaymentMethods}
				legalEntity={legalEntity}
				legalInfo={legalInfo}
				businessAccount={businessAccount}
				onSuccess={(info) => notifyParent({ message: MESSAGE_TYPES.ON_SUCCESS, payload: info })}
				onError={(info) => notifyParent({ message: MESSAGE_TYPES.ON_ERROR, payload: info })}
				redirectUrl={redirectUrl}
				couponCode={couponCode}
				widgetOffset={widgetOffset}
				showEditMode={showEditMode}
				customerReference={customerReference}
			/>
		</WidgetConfigContext.Provider>
	);
};

async function setupServices(token) {
	const billingService = billingServiceFactory({ token });
	const config = await billingService.getConfig();
	const sumupService = sumupServiceFactory(config);
	const epayService = epayServiceFactory({ token });

	return { billingService, sumupService, epayService };
}

export default WidgetContainer;
