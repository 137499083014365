import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Col, Row } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';

import PaymentMethod, { PaymentMethodPreview } from '../components/paymentMethod';
import VatNumber from '../components/vatNumber/VatNumber';
import VatIdPreview from '../components/vatNumber/VatPreview';
import BillingDetailsPreview from '../components/billingDetails/BillingDetailsPreview';
import BillingDetails from '../components/billingDetails/BillingDetails';
import EditContainer from '../components/editContainer';
import useBreakpoint from '../utils/useBreakpoint';
import { Headline } from '../components/headline';
import { NotificationInline } from '../components/notification';
import { PAYMENT_METHODS } from '../constants';

const StyledHeadline = styled(Headline)`
	margin-bottom: ${({ theme }) => theme.spacings.giga};
`;

const StyledNotificationInline = styled(NotificationInline)`
	margin-bottom: ${({ theme }) => theme.spacings.mega};
`;

const EditDetailsForms = observer(({
	customer,
	showBillingDetails,
	showEditMode,
	showVatNumber,
	onPaymentDetailsSubmit,
	onVatNumberSubmit,
	onBillingDetailsSubmit,
	legalEntity,
	variant,
	formSpan = '12',
	businessAccount,
}) => {
	const isMobile = !useBreakpoint();
	const {
		billingDetails,
		paymentDetails,
		paymentMethodType,
		availablePaymentMethods,
	} = customer;
	const missingVatNumber = showVatNumber && !billingDetails;
	const { t } = useTranslation();

	if (!paymentDetails || missingVatNumber) {
		return null;
	}
	const { vatNumber, country } = billingDetails;
	const preview = <PaymentMethodPreview type={paymentMethodType} paymentDetails={paymentDetails} />;

	const onPaymentMethodChange = ({ paymentMethodType, paymentMethod }) => {
		if (paymentMethod.type === PAYMENT_METHODS.businessAccount) {
			return customer.setPaymentMethod(paymentMethodType, businessAccount);
		}
		return customer.setPaymentMethod(paymentMethodType, paymentMethod);
	};

	const multiplePaymentMethods = !!(availablePaymentMethods.directDebit || availablePaymentMethods.businessAccount || availablePaymentMethods.suPayout);

	return <>
		<StyledHeadline as="h3" size='four'>{
			multiplePaymentMethods
				? t('payment_method.title')
				: t('payment_method.credit_card.title')
		}</StyledHeadline>
		<EditContainer
			customer={customer}
			variant={variant}
			showEditMode={showEditMode}
			preview={preview}
			initialValues={{ paymentMethodType }}
			onSuccess={onPaymentDetailsSubmit}
			onCancel={({ paymentMethodType }) => customer.setPaymentMethod(paymentMethodType, {})}
			testId={'payments-form'}
			readonly={paymentDetails && paymentDetails.isDefault}
		>
			<StyledNotificationInline variant="warning" body={t('change_plan.payment_method.disclaimer')} />
			<Row>
				<Col span={isMobile ? '12' : formSpan}>
					<PaymentMethod
						type={paymentMethodType}
						legalEntities={customer.legalEntities}
						countryCode={billingDetails.country}
						availablePaymentMethods={availablePaymentMethods}
						businessAccountEnabled={businessAccount && businessAccount.enabled}
						legalEntity={legalEntity}
						onChange={onPaymentMethodChange}
					/>
				</Col>
			</Row>
		</EditContainer>

		{showBillingDetails && <>
			<StyledHeadline as="h3" size="four">{t('billing_details.title')}</StyledHeadline>
			<EditContainer
				customer={customer}
				variant={variant}
				initialValues={billingDetails}
				preview={<BillingDetailsPreview billingDetails={billingDetails}/>}
				onSuccess={onBillingDetailsSubmit}
				onCancel={(originalBillingDetails) => customer.setBillingDetails(originalBillingDetails)}
				testId={'billing-details-form'}
			>
				<StyledNotificationInline variant="warning" body={t('change_plan.payment_method.disclaimer')} />
				<Row>
					<Col span={isMobile ? '12' : formSpan}>
						<BillingDetails
							onChange={(billingDetails) => customer.setBillingDetails(billingDetails)}
							value={billingDetails}
							showHeader={false}
							disableCountry
						/>
					</Col>
				</Row>
			</EditContainer>
		</>}

		{showVatNumber && <>
			<StyledHeadline as="h3" size="four">{t('vat.title')}</StyledHeadline>
			<EditContainer
				customer={customer}
				variant={variant}
				initialValues={{ vatNumber }}
				preview={<VatIdPreview vatNumber={vatNumber} />}
				onSuccess={onVatNumberSubmit}
				onCancel={({ vatNumber }) => customer.setVatNumber(vatNumber)}
				testId={'vat-form'}
			>
				<StyledNotificationInline variant="warning" body={t('change_plan.payment_method.disclaimer')} />
				<Row>
					<Col span={isMobile ? '12' : formSpan}>
						<VatNumber
							value={vatNumber}
							country={country}
							onChange={(vatNumber) => customer.setVatNumber(vatNumber)}
						/>
					</Col>
				</Row>
			</EditContainer>
		</>}
	</>;
});

EditDetailsForms.propTypes = {
	isEdit: PropTypes.bool,
	onEdit: PropTypes.func,
	onPaymentDetailsSubmit: PropTypes.func.isRequired,
	onVatNumberSubmit: PropTypes.func.isRequired,
	onBillingDetailsSubmit: PropTypes.func.isRequired,
	showBillingDetails: PropTypes.bool,
	showVatNumber: PropTypes.bool,
	customer: PropTypes.shape({
		setPaymentMethod: PropTypes.func.isRequired,
		setVatNumber: PropTypes.func.isRequired,
		billingDetails: PropTypes.object,
	}).isRequired,
	config: PropTypes.shape({
		sumupService: PropTypes.object.isRequired,
		billingService: PropTypes.object.isRequired,
	}).isRequired,
	formSpan: PropTypes.string,
};

export default EditDetailsForms;
