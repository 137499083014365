import React, { useState, useMemo } from 'react';
import CreditCard from './CreditCard';
import DirectDebit from './DirectDebit';
import PaymentMethodSelector  from './PaymentMethodSelector';
import CardNumberPreview from './CreditCard/cardNumberPreview';
import DirectDebitPreview from './DirectDebit/directDebitPreview';
import Payout from './Payout';
import { PAYMENT_METHODS } from '../../constants';

const PaymentMethod = ({
	availablePaymentMethods,
	businessAccountEnabled,
	onChange,
	...rest
}) => {
	const { directDebit, businessAccount, suPayout } = availablePaymentMethods;
	const [paymentMethodType, setPaymentMethodType] = useState(PAYMENT_METHODS.creditCard);
	const handleChange = (paymentMethod) => onChange({
		paymentMethodType,
		paymentMethod,
	});

	const component = useMemo(() => {
		switch (paymentMethodType) {
			case PAYMENT_METHODS.creditCard:
				return <CreditCard onChange={handleChange} {...rest} />;
			case PAYMENT_METHODS.directDebit:
				return  <DirectDebit onChange={handleChange} {...rest} />;
			case PAYMENT_METHODS.businessAccount:
				handleChange({ type: PAYMENT_METHODS.businessAccount });
				return <div data-testid="business-account"></div>;
			case PAYMENT_METHODS.suPayout:
				handleChange({ type: PAYMENT_METHODS.suPayout });
				return <Payout />;
			default:
				return null;
		}
	}, [paymentMethodType]);


	const multiplePaymentMethods = !!(directDebit || businessAccount || suPayout);

	return <>
		{multiplePaymentMethods && <PaymentMethodSelector availablePaymentMethods={availablePaymentMethods} businessAccountEnabled={businessAccountEnabled} onChange={setPaymentMethodType}/>}
		{component}
	</>;
};

export const PaymentMethodPreview = ({ type, paymentDetails }) => {
	if (type === PAYMENT_METHODS.creditCard) {
		const { last4, type } = paymentDetails;

		return <CardNumberPreview last4={last4} type={type}/>;
	} else if (type === PAYMENT_METHODS.directDebit) {
		const { maskedIban, mandateReference  } = paymentDetails;

		return <DirectDebitPreview maskedIban={maskedIban} mandateReference={mandateReference}/>;
	} else if (type === PAYMENT_METHODS.businessAccount) {
		const { maskedIban, mandateReference  } = paymentDetails;

		return <DirectDebitPreview maskedIban={maskedIban} mandateReference={mandateReference}/>;
	}

	return null;
};

export default PaymentMethod;
