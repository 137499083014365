import { observable, action, makeObservable } from 'mobx';
import { PAYMENT_METHODS, EPAY_CARD_PREFIX } from '../constants';
import { countryRegions } from '../constants/country';
import { trace } from '../utils/notifyParent';
import { COUPON_INVALID_TYPES, EPAY_ERROR_CODES, TRACING_EVENTS } from '../../shared/constants';
import {
	InitializationError,
	InternalError,
	PaymentMethodVerificationError,
	CouponValidationError,
	UpdateSubscriptionError,
} from '../utils/errors';
import { request } from '../services/api';

const setNullIfEmptyString = (object, property) => {
	const value = object[property];
	if (typeof value === 'string' && !value.trim()) {
		object[property] = null;
	}
};

const setNullIfNaN = (object, property) => {
	const value = object[property];
	object[property] = +value || null;
};

class Customer {
	isLoading = false;
	billingDetails = null;
	priceDetails = null;
	subscriptionId = null;
	isEdited = false;
	paymentMethodType = null;
	paymentMethod = {};
	paymentDetails = null;
	availablePaymentMethods = {};
	coupon = null;
	couponCode = '';
	customerId = null;
	invalidCouponCode = null;
	planName = '';
	customerReference = '';

	constructor({ sumupService, billingService, epayService }) {
		makeObservable(this, {
			isLoading: observable,
			billingDetails: observable,
			priceDetails: observable,
			subscriptionId: observable,
			isEdited: observable,
			paymentMethodType: observable,
			paymentMethod: observable,
			paymentDetails: observable,
			availablePaymentMethods: observable,
			coupon: observable,
			couponCode: observable,
			customerId: observable,
			planName: observable,
			customerReference: observable,
			setBillingDetails: action,
			setPaymentMethod: action,
			setLegalEntities: action,
			fetchCustomerDetails: action,
			updateBillingDetails: action,
			updateVatNumber: action,
			setVatNumber: action,
			setCouponCode: action,
			purchaseSubscription: action,
			updatePaymentMethod: action,
			updateSubscription: action,
			getBillingSubscription: action,
			validateCouponCode: action,
			setAvailablePaymentMethods: action,
			setIsEdited: action,
			setPlanName: action,
			setCustomerReference: action,
		});

		if (!sumupService || !billingService || !epayService) {
			throw new InitializationError('sumupService and billingService and epayService should be provided');
		}
		this.sumupService = sumupService;
		this.billingService = billingService;
		this.epayService = epayService;
	}

	checkPaymentMethod(paymentMethodType) {
		if (!paymentMethodType) {
			throw new InternalError('No payment method provided');
		}

		switch (paymentMethodType) {
			case PAYMENT_METHODS.creditCard:
			case PAYMENT_METHODS.directDebit:
			case PAYMENT_METHODS.businessAccount:
			case PAYMENT_METHODS.suPayout:
				return true;
			default:
				throw new InternalError('Unknown payment method type', { paymentMethodType });
		}
	}

	setCustomerReference(customerReference) {
		this.customerReference = customerReference;
	}

	setBillingDetails(
		{
			email = '', company = '', state = '', city = '', zip = '',
			addressLine1 = '', addressLine2 = '', phone = '', vatNumber = '',
			firstName = '', lastName = '', invoiceInfo = '',
			country, regionId, regionName
		}
	) {
		const mightHaveRegionSetting = country in countryRegions;
		if (!mightHaveRegionSetting) {
			regionId = null;
			regionName = null;
		}
		this.billingDetails = {
			email, company, country, state, city, zip,
			addressLine1, addressLine2,
			phone, vatNumber, firstName, lastName, invoiceInfo,
			regionId, regionName,
		};
	}

	_extractPaymentMethodDetails(paymentMethod) {
		const { paymentMethodType } = this;
		let paymentMethodDetails;

		if (paymentMethodType === PAYMENT_METHODS.creditCard) {
			paymentMethodDetails = {
				cardType: paymentMethod.creditCard.type,
				last4Digits: paymentMethod.creditCard.last4,
			};
		} else if (paymentMethodType === PAYMENT_METHODS.directDebit) {
			paymentMethodDetails = {
				maskedIban: paymentMethod.directDebit.maskedIban,
			};
		}

		return {
			...paymentMethodDetails,
			paymentMethodType,
		};
	}

	_extractChargeDetails(charge = {}) {
		return {
			amount: charge.amountCents,
			currency: charge.currency,
			renewalDate: charge.endDate,
			planId: charge.planId
		};
	}

	setPaymentMethod(paymentMethodType, paymentMethod) {
		this.checkPaymentMethod(paymentMethodType);

		this.paymentMethodType = paymentMethodType;

		switch (paymentMethodType) {
			case PAYMENT_METHODS.creditCard: {
				const {
					cardholderName,
					cardnumber,
					expiryMonth,
					expiryYear,
					cvv,
				} = paymentMethod;
				this.paymentMethod = {
					cardholderName,
					cardnumber,
					expiryMonth,
					expiryYear,
					cvv,
				};
				break;
			}
			case PAYMENT_METHODS.directDebit: {
				const {
					iban,
					firstName,
					lastName,
				} = paymentMethod;
				this.paymentMethod = {
					iban,
					firstName,
					lastName,
				};
				break;
			}
			case PAYMENT_METHODS.businessAccount: {
				const {
					iban,
					companyName,
				} = paymentMethod;
				this.paymentMethod = {
					iban,
					companyName
				};
				break;
			}
			default:
				break;
		}
	}

	setPlanName(planName) {
		this.planName = planName;
	}

	setLegalEntities(legalEntities) {
		const {
			legalEntity,
			paymentServiceProvider
		} = legalEntities;

		this.legalEntities = {
			legalEntity, paymentServiceProvider
		};
	}

	async fetchCustomerDetails() {
		const customerDetails = await this.billingService.getCustomerDetails();
		if (!customerDetails) {
			return null;
		}
		const defaultPaymentMethod = {
			type: PAYMENT_METHODS.creditCard,
			creditCard: { type: '', last4Digits: '', isDefault: true  }
		};
		const {
			billingDetails,
			paymentMethod = defaultPaymentMethod,
			couponsUsed,
			subscriptionId,
			id: customerId,
		} = customerDetails;
		this.customerId = customerId;
		const { type, creditCard, directDebit } = paymentMethod;
		this.checkPaymentMethod(type);

		this.subscriptionId = subscriptionId;
		this.paymentMethodType = type;
		this.setBillingDetails(billingDetails);

		if (couponsUsed) {
			const relevantCoupon = couponsUsed.find((usage) => {
				return usage.subscriptionId === subscriptionId;
			});
			if (relevantCoupon) {
				this.couponCode = relevantCoupon.code;
			}
		}

		switch (type) {
			case PAYMENT_METHODS.creditCard:
				return this.paymentDetails = creditCard;
			case PAYMENT_METHODS.directDebit:
				return this.paymentDetails = directDebit;
			default:
				return;
		}
	}

	async updateBillingDetails() {
		const billingDetails = { ...this.billingDetails };

		delete billingDetails.vatNumber;
		setNullIfEmptyString(billingDetails, 'phone');
		setNullIfEmptyString(billingDetails, 'state');
		setNullIfEmptyString(billingDetails, 'addressLine2');
		setNullIfEmptyString(billingDetails, 'invoiceInfo');
		setNullIfNaN(billingDetails, 'regionId');

		try {
			await this.billingService.updateBillingInfo({ billingDetails });
			trace(TRACING_EVENTS.BILLING_DETAILS_UPDATED);
		} catch (err) {
			trace(TRACING_EVENTS.BILLING_DETAILS_UPDATE_FAILED, { reason: err.message });

			throw err;
		}

		return { ...this.billingDetails }; // remove proxy
	}

	async updateVatNumber() {
		try {
			await this.billingService.updateBillingInfo({
				billingDetails: {
					vatNumber: this.billingDetails.vatNumber
				},
			});
			trace(TRACING_EVENTS.BILLING_DETAILS_UPDATED);
		} catch (err) {
			trace(TRACING_EVENTS.BILLING_DETAILS_UPDATE_FAILED, { reason: err.message });

			throw err;
		}


		return this.billingDetails.vatNumber;
	}

	setVatNumber(vatNumber) {
		this.billingDetails.vatNumber = vatNumber;
	}

	async setCouponCode({ couponCode, planId }) {
		if (this.isLoading) {
			return;
		}
		// coupon restarted
		if (!couponCode) {
			this.couponCode = '';
			this.coupon = null;
			return;
		}

		this.isLoading = true;

		if (couponCode.startsWith(EPAY_CARD_PREFIX)) {
			try {
				const coupon = await this.epayService.validateCard({ couponCode, planId });
				if (coupon.valid) {
					this.couponCode = coupon.coupon;
					trace(TRACING_EVENTS.EPAY_CARD_VERIFIED, { couponCode });
					this.coupon = coupon;
					this.invalidCouponCode = null;
					this.isLoading = false;

					return;
				} else {
					trace(TRACING_EVENTS.EPAY_CARD_VERIFICATION_FAILED, { couponCode });
				}
			} catch (error) {
				trace(TRACING_EVENTS.EPAY_CARD_VERIFICATION_FAILED, { couponCode });
				if (error.errorCode === EPAY_ERROR_CODES.UNSUPPORTED_FREQUENCY) {
					this.isLoading = false;
					this.invalidCouponCode = COUPON_INVALID_TYPES.UNSUPPORTED_FREQUENCY;
					return;
				}
			}
		}

		try {
			const coupon = await this.billingService.validateCouponCode({ couponCode, planId });

			if (coupon.valid) {
				this.couponCode = couponCode;
				this.invalidCouponCode = null;
				trace(TRACING_EVENTS.COUPON_VERIFIED, { couponCode });
			} else {
				this.couponCode = '';
				this.invalidCouponCode = COUPON_INVALID_TYPES.INVALID_CODE;
				trace(TRACING_EVENTS.COUPON_VERIFICATION_FAILED, { couponCode });
			}

			this.coupon = coupon;
		} catch (err) {
			const internalError = new CouponValidationError(err.message, { couponCode, planId });

			trace(TRACING_EVENTS.COUPON_VERIFICATION_FAILED, { couponCode });
			if (internalError.isHTTPNotFoundError()) {
				this.coupon = { valid: false };
				this.couponCode = '';
				this.invalidCouponCode = COUPON_INVALID_TYPES.INVALID_CODE;
			} else {
				internalError.propagate();
			}
		} finally {
			this.isLoading = false;
		}
	}

	async getUserMandate() {
		const userAgent = window.navigator.userAgent;
		const { userIp } = await request.get('/ip');
		const type = 'recurrent';

		return {
			user_agent: userAgent,
			user_ip: userIp,
			type
		};
	}

	async prepareSubscriptionPurchase({ redirectUrl } = {}) {
		// TODO better solution for strict types
		const billingDetails = { ...this.billingDetails };
		setNullIfNaN(billingDetails, 'regionId');
		const { customerId, checkoutId } = await this.billingService.prepareSubscriptionPurchase({
			billingDetails,
			redirectUrl,
		});
		this.customerId = customerId;
		const requires3dsChallenge = this.paymentMethodType === PAYMENT_METHODS.creditCard;

		/*
		 * Returns one of:
		 * 1 - processed checkout (if 3ds required)
		 * 2 - pending checkout with next_step object (if 3ds required)
		 * 3 - Payment method details (token, card || directDebit)
		 */
		if (requires3dsChallenge) {
			trace(TRACING_EVENTS.SCA_VERIFICATION_STARTED);
			const mandate = await this.getUserMandate();
			return await this.sumupService.processCheckout({
				checkoutId,
				mandate,
				...this.paymentMethod,
			});
		}

		if (this.paymentMethodType === PAYMENT_METHODS.suPayout) {
			return this.preparePayoutPM();
		}

		return await this.tokenizeDirectDebit({ customerId });
	}

	async purchaseSubscription({ planId, paymentMethodData }) {
		const { type, creditCard, directDebit, businessAccount, suPayout } = paymentMethodData;
		const { couponCode } = this;
		const paymentMethodPayload = Object.assign({}, creditCard || directDebit || businessAccount || suPayout);

		if (type === PAYMENT_METHODS.creditCard) {
			const { expiryMonth, expiryYear, cardholderName } = this.paymentMethod;
			const expiry = `${expiryMonth}/${expiryYear}`;

			Object.assign(paymentMethodPayload, { expiry, cardholderName });
		}

		let subscriptionPurchaseData;

		try {
			subscriptionPurchaseData = await this.billingService
				.purchaseSubscription({
					paymentMethodType: type,
					paymentMethodData: paymentMethodPayload,
					planId,
					couponCode
				});
		} catch (err) {
			trace(TRACING_EVENTS.PURCHASE_FAILED, { reason: err.message });

			throw err;
		}

		const { charge, paymentMethod, subscriptionId } = subscriptionPurchaseData;

		this.subscriptionId = subscriptionId;

		trace(TRACING_EVENTS.SUBSCRIPTION_PURCHASED, { subscriptionId });
		return {
			...this._extractPaymentMethodDetails(paymentMethod),
			...this._extractChargeDetails(charge),
		};
	}

	async preparePaymentMethodUpdate({ redirectUrl }) {
		const requires3dsChallenge = this.paymentMethodType === PAYMENT_METHODS.creditCard;
		/*
		 * Returns one of:
		 * 1 - processed checkout (if 3ds required)
		 * 2 - pending checkout with next_step object (if 3ds required)
		 * 3 - Payment method details (token, card || directDebit)
		 */

		if (requires3dsChallenge) {
			trace(TRACING_EVENTS.SCA_VERIFICATION_STARTED);
			const { checkoutId } = await this.billingService.preparePaymentMethodUpdate({
				redirectUrl,
			});
			const mandate = await this.getUserMandate();
			return await this.sumupService.processCheckout({
				checkoutId,
				mandate,
				...this.paymentMethod
			});
		}

		return await this.tokenizeDirectDebit({});
	}

	async updatePaymentMethod(paymentMethodData) {
		const { type, creditCard, directDebit } = paymentMethodData;
		const paymentMethodPayload = creditCard || directDebit;

		if (type === PAYMENT_METHODS.creditCard) {
			const { expiryMonth, expiryYear, cardholderName } = this.paymentMethod;
			const expiry = `${expiryMonth}/${expiryYear}`;

			Object.assign(paymentMethodPayload, { expiry, cardholderName });
		}

		try {
			await this.billingService.updatePaymentMethod({
				paymentMethodType: type,
				paymentMethodData: paymentMethodPayload,
			});
			trace(TRACING_EVENTS.PAYMENT_METHOD_UPDATED);
		} catch (err) {
			trace(TRACING_EVENTS.PAYMENT_METHOD_UPDATE_FAILED, { reason: err.message });
			throw err;
		}

		this.paymentDetails = paymentMethodPayload;

		return this._extractPaymentMethodDetails({
			[this.paymentMethodType]: paymentMethodPayload,
		});
	}

	async tokenizeDirectDebit() {
		this.checkPaymentMethod(this.paymentMethodType);
		const { customerId } = this;
		const paymentMethodPayload = Object.assign({ customerId }, this.paymentMethod);

		const { firstName, lastName, iban } = paymentMethodPayload;
		const company = this.billingDetails.company || paymentMethodPayload.companyName;

		const directDebitData = await this.billingService.addDirectDebit({
			customerId,
			...paymentMethodPayload,
			company_name: company,
		});

		const {
			mandate_reference,
			company_name,
			masked_iban,
			swift,
		} = directDebitData.details;

		return {
			type: PAYMENT_METHODS.directDebit,
			directDebit: {
				mandateReference: mandate_reference,
				companyName: company_name,
				maskedIban: masked_iban,
				iban,
				swift,
				firstName,
				lastName
			}
		};
	}

	async preparePayoutPM() {
		this.checkPaymentMethod(this.paymentMethodType);

		return {
			type: PAYMENT_METHODS.suPayout,
			suPayout: {
				merchantCode: this.customerReference,
			}
		};
	}

	async verifyPaymentMethod({ checkoutId }) {
		let result;
		try {
			result = await this.billingService.verifyPaymentInstrument({ checkoutId });
		} catch (err) {
			trace(TRACING_EVENTS.PAYMENT_METHOD_VERIFICATION_FAILED, { reason: err.message });

			throw err;
		}

		const { verified, paymentMethod } = result;

		if (!verified) {
			trace(TRACING_EVENTS.PAYMENT_METHOD_VERIFICATION_FAILED, { reason: 'Payment method is not valid' });
			throw new PaymentMethodVerificationError('Payment method is not valid', { checkoutId });
		}

		trace(TRACING_EVENTS.PAYMENT_METHOD_VERIFICATION_COMPLETE);

		return paymentMethod;
	}

	async updateSubscription({ planId, subscriptionId }) {
		let result;
		try {
			result = await this.billingService
				.updateSubscription({ subscriptionId, planId });

			trace(TRACING_EVENTS.PLAN_CHANGED);
		} catch (err) {
			trace(TRACING_EVENTS.PLAN_CHANGE_FAILED, { reason: err.message });
			throw new UpdateSubscriptionError(err.message, { subscriptionId, planId });
		}

		const { charge, paymentMethod } = result;
		return {
			...this._extractPaymentMethodDetails(paymentMethod),
			...this._extractChargeDetails(charge),
		};
	}

	async getBillingSubscription(subscriptionId) {
		return await this.billingService.getBillingSubscription(subscriptionId);
	}

	async validateCouponCode({ couponCode, planId }) {
		return this.billingService.validateCouponCode({ couponCode, planId });
	}

	setAvailablePaymentMethods({ creditCard, directDebit, businessAccount, suPayout }) {
		this.availablePaymentMethods = { creditCard, directDebit, businessAccount, suPayout };
	}

	setIsEdited(isEdited = false) {
		this.isEdited = isEdited;
	}
}

export default Customer;
