import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Summary from './Summary';
import { observer } from 'mobx-react';
import OrderSummary from './OrderSummary';
import { CheckoutForm, SubmitButton } from '../helpers/Styled';
import EditDetailsForms from './EditDetailsForms';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledFormDiv = styled.div(({ theme }) => css`
	width: 100%;
	display: flex;
	flex-direction: column;
	${theme.mq.mega} {
		flex-direction: row;
		align-items: flex-start;
	}
`);

const ChangePlan = observer(({
	onSubmit,
	config,
	customer,
	showVatNumber,
	showBillingDetails,
	planId,
	subscriptionId,
	onPaymentDetailsSubmit,
	onVatNumberSubmit,
	onBillingDetailsSubmit,
	legalEntity,
	widgetOffset,
	isMobile,
}) => {
	const { t } = useTranslation();
	const missingVatNumber = showVatNumber && !customer.billingDetails;
	const missingBillingDetails = showBillingDetails && !customer.billingDetails;
	const customerDetailsNotSufficient = !customer.paymentDetails || !customer.subscriptionId;

	if (customerDetailsNotSufficient || missingVatNumber || missingBillingDetails) {
		return null;
	}

	return (
		<StyledFormDiv>
			<CheckoutForm as="section" data-testid="edit-details">
				<EditDetailsForms
					vadiant="update"
					customer={customer}
					config={config}
					showBillingDetails={showBillingDetails}
					showVatNumber={showVatNumber}
					legalEntity={legalEntity}
					onPaymentDetailsSubmit={onPaymentDetailsSubmit}
					onVatNumberSubmit={onVatNumberSubmit}
					onBillingDetailsSubmit={onBillingDetailsSubmit}
				/>
				{!isMobile && (
					<Form onSubmit={onSubmit}>
						{({ submitting, validating, handleSubmit }) => (
							<SubmitButton
								isLoading={submitting || validating}
								onClick={handleSubmit}
								variant="primary"
								disabled={ customer.isEdited }
								loadingLabel="loading"
							>
								{t('purchase_subscription.title_small')}
							</SubmitButton>
						)}
					</Form>
				)}
			</CheckoutForm>
			<OrderSummary
				as="section"
				isMobile={isMobile}
				widgetOffset={widgetOffset}
			>
				<Summary
					config={config}
					subscriptionId={subscriptionId}
					planId={planId}
					billingDetails={customer.billingDetails}
					isMobile={isMobile}
					couponCode={customer.couponCode}
					customer={customer}
				/>
				{isMobile && (
					<Form onSubmit={onSubmit}>
						{({ submitting, validating, handleSubmit }) => (
							<SubmitButton
								isLoading={submitting || validating}
								variant="primary"
								stretch
								isMobile
								onClick={handleSubmit}
								disabled={customer.isEdited}
								loadingLabel="loading"
							>
								{t('purchase_subscription.title_small')}
							</SubmitButton>
						)}
					</Form>
				)}
			</OrderSummary>
		</StyledFormDiv>
	);
});

ChangePlan.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onPaymentDetailsSubmit: PropTypes.func.isRequired,
	onVatNumberSubmit: PropTypes.func.isRequired,
	onBillingDetailsSubmit: PropTypes.func.isRequired,
	showBillingDetails: PropTypes.bool,
	showVatNumber: PropTypes.bool,
	planId: PropTypes.string.isRequired,
	subscriptionId: PropTypes.string.isRequired,
	legalEntity: PropTypes.string,
	customer: PropTypes.shape({
		setPaymentMethod: PropTypes.func.isRequired,
		setVatNumber: PropTypes.func.isRequired,
		billingDetails: PropTypes.object,
		isEdited: PropTypes.bool.isRequired,
	}).isRequired,
	config: PropTypes.shape({
		sumupService: PropTypes.object.isRequired,
		billingService: PropTypes.object.isRequired,
	}).isRequired,
};

export default ChangePlan;
