import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { createForm } from 'final-form';
import { Form } from 'react-final-form';

import OrderSummary from './OrderSummary';
import Summary from './Summary';
import BillingDetails from '../components/billingDetails/BillingDetails';
import PaymentMethod from '../components/paymentMethod';
import VatNumber from '../components/vatNumber/VatNumber';
import LegalInfo from '../components/legalInfo/LegalInfo';
import CouponCode from '../components/couponCode';
import { CheckoutForm, SubmitButton } from '../helpers/Styled';
import setFieldTouched from '../helpers/setFieldTouched';
import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { Headline } from '../components/headline';
import { PAYMENT_METHODS } from '../constants';

const StyledHeadline = styled(Headline)`
	margin-bottom: ${({ theme }) => theme.spacings.giga};
`;

const StyledForm = styled.form(({ theme }) => css`
	width: 100%;
	display: flex;
	flex-direction: column;
	${theme.mq.mega} {
		flex-direction: row;
		align-items: flex-start;
	}
`);

const FormSection = styled.div`
	margin-top: ${({ theme }) => theme.spacings.tera};
`;

const Fieldset = styled.fieldset`
	border: 0;
	margin: 0;
	padding: 0;
`;

const PurchaseSubscription = observer(({
	onSubmit,
	legalInfo,
	businessAccount,
	customer,
	planId,
	config,
	showBillingDetails,
	showVatNumber,
	legalEntity,
	showCoupon,
	widgetOffset,
	isMobile,
}) => {
	const { t } = useTranslation();
	const { billingDetails } = customer;
	const { availablePaymentMethods, coupon, invalidCouponCode, isLoading, couponCode, planName } = customer;

	const onPaymentMethodChange = ({ paymentMethodType, paymentMethod }) => {
		if (paymentMethod.type === PAYMENT_METHODS.businessAccount) {
			return customer.setPaymentMethod(paymentMethodType, businessAccount);
		}
		return customer.setPaymentMethod(paymentMethodType, paymentMethod);
	};

	const multiplePaymentMethods = !!(availablePaymentMethods.directDebit || availablePaymentMethods.businessAccount || availablePaymentMethods.suPayout);

	return (
		<Form
			form={createForm({
				mutators: { setFieldTouched },
				onSubmit,
			})}
		>
			{({ submitting, validating, handleSubmit }) => (
				<StyledForm onSubmit={handleSubmit}>
					<CheckoutForm as="section">
						<Fieldset disabled={submitting}>
							<StyledHeadline as="h2" size='four'>{
								multiplePaymentMethods
									? t('payment_method.title')
									: t('payment_method.credit_card.title')
							}</StyledHeadline>
							<PaymentMethod
								type={customer.paymentMethodType}
								availablePaymentMethods={availablePaymentMethods}
								businessAccountEnabled={businessAccount && businessAccount.enabled}
								legalEntities={customer.legalEntities}
								countryCode={customer.billingDetails.country}
								legalEntity={legalEntity}
								onChange={onPaymentMethodChange}
							/>
							{showBillingDetails && (
								<FormSection>
									<StyledHeadline as="h3" size="four">{t('billing_details.title')}</StyledHeadline>
									<BillingDetails
										value={billingDetails}
										onChange={(billingDetails) => customer.setBillingDetails(billingDetails)}
									/>
								</FormSection>
							)}
							{showVatNumber && (
								<FormSection>
									<StyledHeadline as="h3" size="four">{t('vat.title')}</StyledHeadline>
									<VatNumber
										value={billingDetails.vatNumber}
										country={billingDetails.country}
										onChange={(vatNumber) => customer.setVatNumber(vatNumber)}
									/>
								</FormSection>
							)}
						</Fieldset>
						{!isMobile && (
							<>
								{legalInfo && <LegalInfo source={legalInfo} />}
								<SubmitButton isLoading={submitting || validating} variant="primary" loadingLabel="loading">
									{t('purchase_subscription.title')}
								</SubmitButton>
							</>
						)}
					</CheckoutForm>
					<OrderSummary
						as="section"
						isMobile={isMobile}
						widgetOffset={widgetOffset}
					>
						<>
							<Summary
								config={config}
								planId={planId}
								country={customer.billingDetails.country}
								vatNumber={customer.billingDetails.vatNumber}
								isMobile={isMobile}
								couponCode={couponCode}
								showCoupon={showCoupon}
								customer={customer}
							/>
							{showCoupon && <CouponCode
								couponCode={couponCode}
								coupon={coupon}
								planName={planName}
								invalidCouponCode={invalidCouponCode}
								onChange={(couponCode) => customer.setCouponCode({ couponCode, planId })}
								isLoading={isLoading}
							/>}
						</>
						{isMobile && (
							<>
								{legalInfo && <LegalInfo source={legalInfo} isMobile />}
								<SubmitButton
									isLoading={submitting || validating}
									loadingLabel="loading"
									variant="primary"
									stretch
									isMobile
								>
									{t('purchase_subscription.title')}
								</SubmitButton>
							</>
						)}
					</OrderSummary>
				</StyledForm>
			)}
		</Form>
	);
});

PurchaseSubscription.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	planId: PropTypes.string.isRequired,
	legalInfo: PropTypes.string,
	showBillingDetails: PropTypes.bool,
	showVatNumber: PropTypes.bool,
	customer: PropTypes.shape({
		setPaymentMethod: PropTypes.func.isRequired,
		setVatNumber: PropTypes.func.isRequired,
		billingDetails: PropTypes.object.isRequired,
	}).isRequired,
	config: PropTypes.shape({
		sumupService: PropTypes.object.isRequired,
		billingService: PropTypes.object,
		epayService: PropTypes.object
	}).isRequired,
};

export default PurchaseSubscription;
