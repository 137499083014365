import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { spacing } from '@sumup/circuit-ui/legacy';
import { Tag, Button } from '@sumup/circuit-ui';
import { useTranslation } from 'react-i18next';
import { FullWidthShimmer } from '../shimmer';
import styled from '@emotion/styled';
import { Input } from '../input';
import { Body } from '../body';
import { COUPON_INVALID_TYPES } from '../../../shared/constants';

const TagLabel = styled(Body)`${({ theme }) => `
	margin-bottom: ${theme.spacings.mega}
`}`;

const ApplyButton = styled(Button)`
	padding-top: 0;
	padding-left: 0;
	border-radius: 0;
	&:active, &:focus {
		box-shadow: none;
		outline: none;
	}
	&:hover {
		background-color: transparent;
	}
`;

const CouponContainer = styled('div')`
	width: 100%;
`;

const CouponTag = styled(Tag)`
	display: inline-block;
`;

const CouponCode = ({ couponCode, coupon, invalidCouponCode, planName, onChange, isLoading }) => {
	const { t } =  useTranslation();
	const [value, setValue] = useState(couponCode);
	const [isCouponInvalid, setIsCouponInvalid] = useState(false);

	useEffect(() => {
		setIsCouponInvalid(!!invalidCouponCode);
	}, [invalidCouponCode]);
	const removeCoupon = useCallback(() => {
		setValue('');
		onChange('');
	});
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			onChange(value);
		}
		isCouponInvalid && setIsCouponInvalid(false);
	};

	if (isLoading) {
		return <>
			<FullWidthShimmer>{t('coupon_code.title')}</FullWidthShimmer>
			<FullWidthShimmer>{t('coupon_code.apply')}</FullWidthShimmer>
		</>;
	}

	if (coupon && coupon.valid) {
		return <div data-testid='coupon-code-tag'>
			<TagLabel size='two'>{t('coupon_code.title')}</TagLabel>
			<CouponTag
				as={'div'}
				label={t('coupon_code.title')}
				onRemove={removeCoupon}
				selected={false}
				removeButtonLabel={couponCode}
			>
				{couponCode}
			</CouponTag>
		</div>;
	}

	let validationHint = undefined;
	if (isCouponInvalid) {
		if (invalidCouponCode === COUPON_INVALID_TYPES.UNSUPPORTED_FREQUENCY) {
			validationHint = t('error.validation.invalid_coupon_frequency', { planName });
		} else {
			validationHint = t('error.validation.invalid_coupon_code');
		}
	}

	return <CouponContainer data-testid='coupon-code'>
		<Input
			label={t('coupon_code.title')}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			type="text"
			readOnly={isLoading}
			invalid={isCouponInvalid}
			onKeyDown={handleKeyDown}
			validationHint={validationHint}
			css={spacing({ bottom: 'mega' })}
		/>
		<ApplyButton
			type="button"
			variant="tertiary"
			onClick={() => onChange(value.trim())}
			isLoading={isLoading}
			loadingLabel="loading"
		>
			{t('coupon_code.apply')}
		</ApplyButton>
	</CouponContainer>;
};

CouponCode.propTypes = {
	couponCode: PropTypes.string,
	isLoading: PropTypes.bool,
	coupon: PropTypes.shape({
		valid: PropTypes.bool,
	}),
	onChange: PropTypes.func.isRequired,
};

export default CouponCode;
