import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body } from '../../body';
import styled from '@emotion/styled';

const Container = styled.div`
	${({ theme }) => `
		display: flex;
		background-color: var(--cui-bg-subtle);
		padding: ${theme.spacings.giga};
		gap: ${theme.spacings.mega};
		border-radius: ${theme.borderRadius.kilo};
	`}
`;

const StyledBody = styled(Body)`
	font-weight: 600;
`;

const Payout = () => {
	const { t } = useTranslation();

	return (
		<Container data-testid="payout">
			<img src="/payout.svg" />
			<StyledBody size="s">
				{t('payment_method.payout.note')}
			</StyledBody>
		</Container>
	);
};

export default Payout;
